import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {CalendarModule} from 'primeng/calendar';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ConfirmationService} from 'primeng/api';

@NgModule({
  imports: [
    CommonModule,
    CalendarModule,
    AutoCompleteModule,
    ConfirmDialogModule,
    // ConfirmationService
  ],
  exports: [
    CalendarModule,AutoCompleteModule,ConfirmDialogModule,
  ],
  providers:[ConfirmationService]
})
export class SbiSharedModule { }

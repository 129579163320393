import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class LoginAuthGuard implements CanActivate {
  constructor(private router:Router){}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      var userData = JSON.parse(localStorage.getItem('UserData'));
      if(userData!=undefined || userData!=null){
        this.router.navigate(['dashboard']);
        return false;
      }
    return true;
  }
}

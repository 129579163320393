import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(public router:Router){}
  // token:any
  canActivate(
    
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      // const UserDataCheck = localStorage.getItem('UserData');
      // console.log(UserDataCheck);
       var userData = JSON.parse(localStorage.getItem('UserData'));
  	  //  console.log(userData);
  	    // var id = userData.id; 

  	if(userData == undefined || userData.length == 0 || userData == null){
  		this.router.navigate(['']);
  		return false;
  	}
    return true;
  }
}

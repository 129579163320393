// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // url:'http://172.10.1.5:8041/backend/public/api/',
  // imagePath:'http://172.10.1.5:8041/backend/public/images/',
  // videoPath:'http://172.10.1.5:8041/backend/public/videos/'

  // staging url
  //  url: 'http://34.211.31.84:9054/backend/public/api/',
  //  imagePath:'http://34.211.31.84:9054/backend/public/images/',
  // videoPath:'http://34.211.31.84:9054/backend/public/videos/'

  //live url
  //  url: 'http://54.71.164.37/backend/public/api/',
    url: 'http://www.funinmotiontoysapp.com/backend/public/api/',
    imagePath:'http://www.funinmotiontoysapp.com/backend/public/images/',
    videoPath:'http://www.funinmotiontoysapp.com/backend/public/videos/'
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { adminLteConf } from './admin-lte.conf';
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
import { LayoutModule } from 'angular-admin-lte';
import { AppComponent } from './app.component';
// import { HomeComponent } from './home/home.component';
import { LoadingPageModule, MaterialBarModule } from 'angular-loading-page';
import {AuthGuard} from './auth.guard';
import { LoginAuthGuard } from './login-auth.guard';
import { LinkUsedComponent } from './link-used/link-used.component';
import {DataTableModule} from "angular-6-datatable";
import {TableModule} from 'primeng/table';
import { SbiSharedModule } from './sbi-shared/sbi-shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SbiSharedModule,
    AppRoutingModule,
    CoreModule,
    LayoutModule.forRoot(adminLteConf),
    LoadingPageModule, MaterialBarModule,
    DataTableModule,
    TableModule
    
  ],
  declarations: [
    AppComponent,
    LinkUsedComponent,
    PageNotFoundComponent,
    PrivacyPolicyComponent,
  ],
  providers:[AuthGuard,LoginAuthGuard],
  bootstrap: [AppComponent]
})
export class AppModule {}
export const adminLteConf = {
  skin: 'blue',
  // isSidebarLeftCollapsed: false,
  // isSidebarLeftExpandOnOver: false,
  // isSidebarLeftMouseOver: false,
  // isSidebarLeftMini: true,
  // sidebarRightSkin: 'dark',
  // isSidebarRightCollapsed: true,
  // isSidebarRightOverContent: true,
  // layout: 'normal',
  sidebarLeftMenu: [
    {label: 'MAIN NAVIGATION', separator: true},
    {label: 'Dashboard', route: 'dashboard', iconClasses: 'fa fa-tachometer'},
    {label: 'Products',route:'product',iconClasses:'fa fa-shopping-cart'},
    {label: 'Inspiration',route:'inspiration', iconClasses:'fa fa-video-camera'},
    {label: 'Levels',route:'level',iconClasses:'fa fa-video-camera'},
    {label: 'Instructional',route:'video',iconClasses:'fa fa-video-camera'},
    {label: 'My Profile',route:'profile' ,iconClasses:'fa fa-user'},
    {label: 'Setting',route:'setting',iconClasses:'fa fa-cogs'},
    {label: 'Trash', iconClasses:'fa fa-trash', children:[
      {label:'Product', route:'trash/product', iconClasses:'fa fa-shopping-cart'},
      {label:'Inspiration', route:'trash/inspiration', iconClasses:'fa fa-video-camera'},
      {label:'Instructional', route:'trash/instructional', iconClasses:'fa fa-video-camera'},
      {label:'Level', route:'trash/level', iconClasses:'fa fa-video-camera'},
    ]}
  ]
};

import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  public userName = new ReplaySubject<Object>();
  userData:any
  constructor() { }

  setUserName(user){
    // console.log('userData hello=>',user.name)
    this.userName.next(user.name)
  }

  getUserName(){
    // console.log('getusername called')
    return this.userName.asObservable();
  }
}

import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth.guard';
import { LoginAuthGuard } from './login-auth.guard';
import {LinkUsedComponent} from './link-used/link-used.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: './login/login.module#LoginModule',
    data:{
      customLayout:true
    },
    canActivate:[LoginAuthGuard]
  },

  {
    path:'dashboard',
    loadChildren:'./home/home.module#HomeModule',
    data:{
        title:'Dashboard'
    },
    canActivate:[AuthGuard]
  },
  {
    path:'trash',
    loadChildren:'./trash/trash.module#TrashModule',
    data:{
      title:'Trash'
    }
  },
  {
    path:'link-used',
    component:LinkUsedComponent,
    data:{
      customLayout:true
    },
  },

  
  {
    path: 'forget-password',
    loadChildren: './forget-password/forget-password.module#ForgetPasswordModule',
    data:{
      customLayout:true
    },
  },
  {
    path: 'linkexpire',
    loadChildren:'./forget-password/link-expire.module#LinkExpireModule',
    data:{
      customLayout:true
    },
  },
  {
    path: 'reset-password/:token',
    loadChildren: './reset-password/reset-password.module#ResetPasswordModule',
    data:{
      customLayout:true
    },
  },
  {
    path: 'reset-success',
    loadChildren: './reset-success/reset-success.module#ResetSuccessModule',
    data:{
      customLayout:true
    },

  },
  {
    path:'product',
    loadChildren:'./product/product.module#ProductModule',
    data:{
      title:'Product'
    },
    canActivate:[AuthGuard]
  },
  {
    path:'inspiration',
    loadChildren:'./video/inspiration/inspiration.module#InspirationModule',
    data:{
      title:'Inspiration'
    },
    canActivate:[AuthGuard]
  },
  
  {
    path:'level',
    loadChildren:'./video/instruction/instruction.module#InstructionModule',
    data:{
      title:'Level'
    },
    canActivate:[AuthGuard]
  },
  {
    path:'video',
    loadChildren:'./video/video.module#VideoModule',
    data:{
      title:'Instructional Video'
    },
    canActivate:[AuthGuard]
  },
  {
    path:'profile',
    loadChildren:'./profile/profile.module#ProfileModule',
    data:{
      title:'Profile'
    },
    canActivate:[AuthGuard]
  },
  {
    path:'setting',
    loadChildren:'./setting/setting.module#SettingModule',
    data:{
      title:'Setting'
    },
    canActivate:[AuthGuard]
  },
  {
    path:'home',
    loadChildren:'./home/home.module#HomeModule',
    data:{
      title:'Home'
    }
  },
  {
    path:'privacy-policy',
    component:PrivacyPolicyComponent,
    data:{
      customLayout:true
    }
  },
  {
    path:'**',
    component:PageNotFoundComponent,
    data:{
      customLayout:true
    }
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

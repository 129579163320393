import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from '../../app.service';
@Component({
  selector: 'app-header-inner',
  templateUrl: './header-inner.component.html'
})
export class HeaderInnerComponent {
  name:any
  constructor(private router:Router, private appService:AppService){}
  onSignOut(){
    localStorage.clear();
    this.router.navigate(['']);
  }
  ngOnInit(){
    var userData = JSON.parse(localStorage.getItem('UserData'));
    this.name=userData.name
    this.appService.getUserName()
    .subscribe((result)=>{
      // console.log('on result header',result)
      this.name=result
    })
  }

  myProfile(){
    this.router.navigate(['profile'])
  }
}
